import { Injectable, inject } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { switchMap, catchError, of } from 'rxjs';

import { SearchService } from '@offconon/admin/data-access/apis';

import * as CategoryTemplateAdminActions from './category-template-admin.actions';

@Injectable()
export class CategoryTemplateAdminEffects {
  private actions$ = inject(Actions);
  private searchService = inject(SearchService);
  private translateService = inject(TranslateService);
  private messageService = inject(MessageService);

  loadCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CategoryTemplateAdminActions.loadCategoryTemplateAdmin),

      switchMap((action) => {
        CategoryTemplateAdminActions.setLoadingState({ loading: true });
        return this.searchService
          .searchCategoryTemplateList(action.page, action.pageSize, {
            id: action.id,
            language_id: action.language_id,
          })
          .pipe(
            switchMap((categoryTemplateAdmin) => {
              return of(
                CategoryTemplateAdminActions.loadCategoryTemplateAdminSuccess({
                  categoryTemplateAdmin,
                }),
              );
            }),
            catchError((error) =>
              of(CategoryTemplateAdminActions.loadCategoryTemplateAdminFailure({ error })),
            ),
          );
      }),
    ),
  );
  deleteCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CategoryTemplateAdminActions.deleteCategory),

      switchMap((action) => {
        return this.searchService.searchCategoryTemplateDestroy(action.id).pipe(
          switchMap(() => {
            this.messageService.add({
              severity: 'info',
              summary: this.translateService.instant('Delete'),
              detail: this.translateService.instant('The item has been deleted!'),
            });
            return of(
              CategoryTemplateAdminActions.deleteCategorySuccess({
                id: action.id,
              }),
            );
          }),
          catchError((error) =>
            of(CategoryTemplateAdminActions.loadCategoryTemplateAdminFailure({ error })),
          ),
        );
      }),
    ),
  );
}
