import { Routes } from '@angular/router';

export const AdminFeaturesCategoryTemplateAdminRoutes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('./pages/category-template-admin/category-template-admin.component').then(
        (m) => m.CategoryTemplateAdminComponent,
      ),
  },
  {
    path: 'filter/:id',

    loadComponent: () =>
      import('./pages/category-template-admin/category-template-admin.component').then(
        (m) => m.CategoryTemplateAdminComponent,
      ),
  },
  {
    path: 'edit/:id',
    loadComponent: () =>
      import(
        './pages/category-template-admin-create/category-template-admin-create.component'
      ).then((m) => m.CategoryTemplateAdminCreateComponent),
  },
  {
    path: 'create',
    loadComponent: () =>
      import(
        './pages/category-template-admin-create/category-template-admin-create.component'
      ).then((m) => m.CategoryTemplateAdminCreateComponent),
  },
];
